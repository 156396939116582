import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

import Theme from "../../../entity/theme/theme"
export let rows, bestData, monthData, standardDeviationData

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        overflowX: "auto",
    },
    table: {
        width: "100%",
        alignContent: "center",
        textAlign: "center",
        border: "1px solid rgba(224, 224, 224, 1)",
    },
    row: {
        fontWeight: "bold",
        //backgroundColor: "pink",
        fontFamily: Theme.font.fontFamily,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        "@media screen and (max-width:480px) ": {
            fontSize: "12px",
        },
        padding: "8px",
    },
    border: {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        padding: "12px",
        fontFamily: Theme.font.fontFamily,
    },
    hitRate: {
        color: Theme.color.hitNumColor,
        fontFamily: Theme.font.fontFamily,
    },
    difference: {
        color: "red",
    },
}))

function createData(item, hitArrow, allArrow, rate, difference) {
    let hitRate = Math.round(rate)
    return { item, hitArrow, allArrow, hitRate, difference }
}

function rateMonth(sortRecords) {
    const monthData = {
        hit_arrow: 0,
        all_arrow: 0,
    }
    sortRecords.map((data) => {
        monthData.hit_arrow += data.hit_arrow
        monthData.all_arrow += data.all_arrow
    })
    monthData.hit_rate = (monthData.hit_arrow / monthData.all_arrow) * 100
    return monthData
}

function bestMonth(sortRecords) {
    let data = {
        hit_arrow: sortRecords[0].hit_arrow,
        all_arrow: sortRecords[0].all_arrow,
        hit_rate: sortRecords[0].hit_rate,
    }
    for (let i = 1; i < sortRecords.length; i++) {
        if (data.hit_rate < sortRecords[i].hit_rate) {
            data.hit_arrow = sortRecords[i].hit_arrow
            data.all_arrow = sortRecords[i].all_arrow
            data.hit_rate = sortRecords[i].hit_rate
        }
    }

    return data
}
function standardDeviation(sortRecords) {
    let average = {
        hit_arrow: 0,
        all_arrow: 0,
        hit_rate: 0,
    }
    ////console.log({ sortRecords })
    average.hit_arrow =
        sortRecords.reduce((previous, current) => {
            return previous + current.hit_arrow
        }, 0) / sortRecords.length
    average.all_arrow =
        sortRecords.reduce((previous, current) => {
            return previous + current.all_arrow
        }, 0) / sortRecords.length
    average.hit_rate =
        sortRecords.reduce((previous, current) => {
            return previous + current.hit_rate
        }, 0) / sortRecords.length
    let twoData = sortRecords.map((current) => {
        // 各要素について
        let data = {
            hit_arrow: (current.hit_arrow - average.hit_arrow) ** 2,
            all_arrow: (current.all_arrow - average.all_arrow) ** 2,
            hit_rate: (current.hit_rate - average.hit_rate) ** 2,
        }
        return data // 差を2乘する
    })

    let reslut = {
        hit_arrow: 0,
        all_arrow: 0,
        hit_rate: 0,
    }
    reslut.hit_arrow = Math.round(
        Math.sqrt(
            twoData.reduce((previous, current) => {
                return previous + current.hit_arrow
            }, 0) / sortRecords.length
        )
    )
    reslut.all_arrow = Math.round(
        Math.sqrt(
            twoData.reduce((previous, current) => {
                return previous + current.all_arrow
            }, 0) / sortRecords.length
        )
    )
    reslut.hit_rate = Math.round(
        Math.sqrt(
            twoData.reduce((previous, current) => {
                return previous + current.hit_rate
            }, 0) / sortRecords.length
        )
    )

    return reslut
}
export default function MonthTable(props) {
    const classes = useStyles()
    const { records } = props
    const compare = (a, b) => {
        let aSplit = a.date.split("/")
        let bSplit = b.date.split("/")
        const dateA = Number(aSplit[2])
        const dateB = Number(bSplit[2])
        let comparison = 0
        if (dateA >= dateB) {
            comparison = 1
        } else if (dateA < dateB) {
            comparison = -1
        }
        return comparison
    }

    // let sortRecords = JSON.parse(JSON.stringify(records));;
    // sortRecords.sort(compare).reverse();

    let sortWill = [...records]
    let sortRecords = sortWill.sort(compare).reverse()

    let lastRecords = [...records].reverse()[0]

    if (sortRecords.length == 0) {
        rows = [
            createData("最終記録日", null, null, null),
            // yesterdayData,
            createData("今月", null, null, null),
            createData("今月のベスト記録", null, null, null),
            createData("標準偏差(データのばらつき)", null, null, null),
        ]
    } else {
        bestData = bestMonth(sortRecords)
        monthData = rateMonth(sortRecords)
        standardDeviationData = standardDeviation(sortRecords)
        rows = [
            createData(
                "最終記録日",
                lastRecords.hit_arrow,
                lastRecords.all_arrow,
                lastRecords.hit_rate
            ),
            // yesterdayData,
            createData("今月", monthData.hit_arrow, monthData.all_arrow, monthData.hit_rate),
            createData(
                "今月のベスト記録",
                bestData.hit_arrow,
                bestData.all_arrow,
                bestData.hit_rate
            ),
            createData(
                "標準偏差(データのばらつき)",
                standardDeviationData.hit_arrow,
                standardDeviationData.all_arrow,
                standardDeviationData.hit_rate
            ),
        ]
    }

    return (
        <>
            <div className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: "pink" }}>
                            <TableCell className={classes.row}>項目</TableCell>
                            <TableCell align="right" className={classes.row}>
                                的中数
                            </TableCell>
                            <TableCell align="right" className={classes.row}>
                                本数
                            </TableCell>
                            <TableCell align="right" className={classes.row}>
                                的中率
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.item}>
                                <TableCell component="th" scope="row" className={classes.row}>
                                    {row.item}
                                </TableCell>
                                <TableCell align="right" className={classes.border}>
                                    {row.hitArrow}本
                                </TableCell>
                                <TableCell align="right" className={classes.border}>
                                    {row.allArrow}本
                                </TableCell>
                                <TableCell align="right" className={classes.hitRate}>
                                    <div>{row.hitRate}%</div>
                                    {/* {row == todayData ? <span style={{ color: "black" }}>前日比：<span className={classes.difference}>{row.difference}%</span></span> : null} */}
                                </TableCell>
                                {/* <TableCell align="right" className={classes.hitRate}>{row.hitRate}%</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}
