import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import Theme from "../../../entity/theme/theme"

const useStyles = makeStyles(() => ({
    card: {
        minWidth: 275,
        padding: "3px",
        paddingBottom: "2px",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        color: Theme.color.primaryColor,
        borderBottom: "solid",
        display: "inline-block",
        marginTop: "10px",
        fontSize: "19px",
    },
    pos: {
        marginBottom: 12,
    },
}))

export default function RecordsCard(props) {
    const classes = useStyles()
    const { title, subtitle } = props

    return (
        <>
            <Card style={{ boxShadow: "none" }}>
                <CardContent className={classes.card}>
                    <Typography className={classes.title}>
                        <div style={{ display: "flex" }}>
                            <div style={fontStyle("bold")}> {title}</div>
                        </div>
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        <span style={fontStyle("500")}> {subtitle}</span>
                    </Typography>
                    <Typography variant="body2" component="p">
                        <span style={fontStyle("500")}> {props.children}</span>
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}
