import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import styled from "styled-components"

import swalErrorAction from "../entity/alert/swalErrorAction"
import { networkError } from "../entity/alert/swalErrorList"
import Theme from "../entity/theme/theme"
import BoxLoading from "../presentation/atoms/boxLoading"
import Graph from "../presentation/molecules/graph"
import SelectPicker from "../presentation/molecules/selectPicker"
import CommentBox, { desideComment } from "../presentation/organisms/records/commentBox"
import MonthTable from "../presentation/organisms/records/monthTable"
import PictureSaveButton from "../presentation/organisms/records/pictureSaveButton"
import RecordsCalendar from "../presentation/organisms/records/recordsCalendar"
import RecordsCard from "../presentation/organisms/records/recordsCard"
import TweetButton from "../presentation/organisms/records/tweetButton"
import { recordsRepository, userRepository } from "../repository/action"

import FrameTemplate from "@/presentation/templates/frameTemplate"

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff",
        fontFamily: Theme.font.fontFamily,
        overflowY: "auto",
        overflowX: "hidden",
    },
    calendar: {
        display: "flex",
        marginTop: "10px",
    },
    calendarIcon: {
        top: "3px",
        position: "relative",
        marginRight: "5px",
    },
    calendarText: {
        top: "10px",
        position: "relative",
        color: "rgba(0, 0, 0, 0.54)",
    },
    buttonStyle: {
        width: "100%",
        height: "100%",
        margin: "auto",
        textAlign: "center",
    },
    margin: {
        margin: "25% 0%",
    },
    action: {
        position: "relative",
        margin: "10px 0px -30px;",
        textAlign: "right",
    },
}))

export default function Records(props) {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false)
    const [records, setRecords] = React.useState()
    const [monthAllRecords, setMonthAllRecords] = React.useState({})
    const [loginUser, setLoginUser] = React.useState()
    const [memberData, setMemberData] = React.useState()
    const [rangeDate, setRangeDate] = React.useState({
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
    })
    const [distinction, setDistinction] = React.useState()
    const [title, setTitle] = React.useState("記録")
    const [notificationCount, setNotificationCount] = React.useState(0)
    const [selectedPicker] = React.useState("month")

    const [sentense, setSentense] = React.useState({})
    const [commentText, setCommentText] = React.useState("")

    const handleChange = (event, value) => {
        setDistinction(value)
    }

    React.useEffect(() => {
        if (props.location.action) {
            if (props.location.state.loginUser) {
                setLoginUser(props.location.state.loginUser)
                let setUserjson = JSON.stringify(props.location.state.loginUser)
                localStorage.setItem(["loginUser"], [setUserjson])
            }
            if (props.location.state.memberData) {
                setMemberData(props.location.state.memberData)
            }
            setDistinction(props.location.state.distinction)
            let distinctionjson = JSON.stringify(props.location.state.distinction)
            localStorage.setItem(["distinction"], [distinctionjson])
            if (props.location.state.monthAllRecords) {
                setMonthAllRecords(props.location.state.monthAllRecords)
            }
        } else {
            let getUserjson = localStorage.getItem(["loginUser"])
            if (getUserjson) {
                let getDistinctionjson = localStorage.getItem(["distinction"])
                userRepository
                    .sessionLogin(JSON.parse(getUserjson).email, JSON.parse(getUserjson).password)
                    .then((data) => {
                        setLoginUser(data.user_info)
                        setMemberData(data.memberData)
                        setRecords(true)
                        recordsRepository
                            .allRecordsFetch(data.user_info)
                            .then((data2) => {
                                setMonthAllRecords(data2)
                                setDistinction(JSON.parse(getDistinctionjson))
                            })
                            .catch(() => {
                                swalErrorAction(networkError)
                            })
                    })
                    .catch(() => {
                        swalErrorAction(networkError)
                    })
            } else {
                navigate("/")
            }
        }
    }, [])
    React.useEffect(() => {
        if (memberData) {
            let notification_count = 0
            memberData.forEach((v) => {
                if (v.permission == "1") {
                    notification_count++
                }
            })
            setNotificationCount(notification_count)
        }
    }, [memberData])

    React.useEffect(() => {
        let my_records = []
        switch (distinction) {
            case 0:
                my_records = monthAllRecords.records.filter((value) => {
                    let date = moment(value.date).format("X")
                    if (
                        rangeDate.startDate.format("X") <= date &&
                        date <= rangeDate.endDate.format("X")
                    ) {
                        return value
                    }
                })
                setRecords(my_records)
                setTitle("通常練習 記録")
                localStorage.setItem(["distinction"], 0)
                break
            case 1:
                my_records = monthAllRecords.records2.filter((value) => {
                    let date = moment(value.date).format("X")
                    if (
                        rangeDate.startDate.format("X") <= date &&
                        date <= rangeDate.endDate.format("X")
                    ) {
                        return value
                    }
                })
                setRecords(my_records)
                setTitle("強化練習 記録")
                localStorage.setItem(["distinction"], 1)
                break
            case 2:
                my_records = monthAllRecords.records3.filter((value) => {
                    let date = moment(value.date).format("X")
                    if (
                        rangeDate.startDate.format("X") <= date &&
                        date <= rangeDate.endDate.format("X")
                    ) {
                        return value
                    }
                })
                setRecords(my_records)
                setTitle("自主練習 記録")
                localStorage.setItem(["distinction"], 2)
                break
        }

        let newRecords = [...my_records].reverse()
        let displayDate =
            rangeDate.startDate.format("YYYY") + "年" + rangeDate.startDate.format("M") + "月"
        let sentenseArray = [
            {
                title: "的中カレンダー",
                subtitle: displayDate,
                id: "hitrate_records_calendar",
            },
            {
                title: "的中グラフ",
                subtitle: displayDate,
                id: "hitrate_graph",
            },
            {
                title: "的中表",
                subtitle: displayDate,
                id: "hitrate_table",
            },
            {
                title: "AIによる考察",
                subtitle: newRecords[0] ? newRecords[0].date + "(最終記録日)" : "",
                id: "ai_consideration",
            },
        ]
        setSentense(sentenseArray)
        let comment
        if (newRecords.length > 0) {
            comment = desideComment(newRecords[0].hit_arrow, newRecords[0].hit_rate)
            setCommentText(comment)
        } else {
            comment = "データがないよ"
            setCommentText(comment)
        }
    }, [monthAllRecords, rangeDate, distinction])

    return (
        <div className={classes.root}>
            <FrameTemplate
                loginUser={loginUser}
                setLoginUser={setLoginUser}
                setIsLoading={setIsLoading}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
                memberData={memberData}
                setMemberData={setMemberData}
                setTitle={setTitle}
                title={title}
                notificationCount={notificationCount}
                setMonthAllRecords={setMonthAllRecords}
                setDistinction={setDistinction}
                setRecords={setRecords}
            />
            <Contents>
                {!loginUser || !memberData || isLoading ? (
                    <BoxLoading />
                ) : (
                    <div style={{ height: "100%", padding: "52px 0px 100px 0px" }}>
                        <Tabs
                            value={distinction}
                            onChange={handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            textColor="primary"
                        >
                            <Tab label="通常練習" style={fontStyle("bold")} />
                            <Tab label="強化練習" style={fontStyle("bold")} />
                            <Tab label="自主練習" style={fontStyle("bold")} />
                        </Tabs>
                        <div className={classes.calendar}>
                            <SelectPicker
                                rangeDate={rangeDate}
                                setRangeDate={setRangeDate}
                                selectedPicker={selectedPicker}
                            />
                        </div>
                        {sentense && records && records.length >= 0 && (
                            <>
                                <div className={classes.action}>
                                    <PictureSaveButton
                                        id={"hitrate_records"}
                                        subtitle={records[0] ? records[0].date : "nodata"}
                                    />
                                    <TweetButton
                                        records={records}
                                        distinction={distinction}
                                        commentText={commentText}
                                    />
                                </div>
                                <div id={"hitrate_records"}>
                                    {sentense.map((value, i) => {
                                        return (
                                            <RecordsCard
                                                key={"sentense" + i}
                                                title={value.title}
                                                subtitle={value.subtitle}
                                            >
                                                {i === 0 && (
                                                    <RecordsCalendar
                                                        records={records}
                                                        memberData={memberData}
                                                        rangeDate={rangeDate}
                                                        loginUser={loginUser}
                                                        distinction={distinction}
                                                        setRecords={setRecords}
                                                        setMonthAllRecords={setMonthAllRecords}
                                                    />
                                                )}
                                                {i === 1 && <Graph records={records} />}
                                                {i === 2 && <MonthTable records={records} />}
                                                {i === 3 && (
                                                    <CommentBox commentText={commentText} />
                                                )}
                                            </RecordsCard>
                                        )
                                    })}
                                </div>
                            </>
                        )}
                        <div style={{ paddingBottom: "70px" }} />
                    </div>
                )}
            </Contents>
        </div>
    )
}

const Contents = styled.div`
    height: 100vh;
    background: #fff;
`
