import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import ImageIcon from "@material-ui/icons/Image"
import html2canvas from "html2canvas"
import React from "react"

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: "bold",
        textTransform: "lowercase",
    },
    waitCircle: {
        position: "fixed",
        left: "43%",
        top: "50%",
        //zIndex:100000
    },
}))

export default function PictureSaveButton(props) {
    const classes = useStyles()
    const { id, subtitle } = props
    let downloadLink, target
    const saveAsImage = (uri) => {
        downloadLink = document.createElement("a")

        if (typeof downloadLink.download === "string") {
            downloadLink.href = uri

            // ファイル名
            downloadLink.download = `${id}_${subtitle}.png`

            // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
            document.body.appendChild(downloadLink)

            // ダウンロードリンクが設定された a タグをクリック
            downloadLink.click()

            // Firefox 対策で追加したリンクを削除しておく
            document.body.removeChild(downloadLink)
        } else {
            window.open(uri)
        }
    }

    const onClickExport = () => {
        // 画像に変換する component の id を指定
        target = document.getElementById(id)
        html2canvas(target).then((canvas) => {
            const targetImgUri = canvas.toDataURL("img/png")
            saveAsImage(targetImgUri)
        })
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onClickExport}
                className={classes.button}
                startIcon={<ImageIcon />}
            >
                保存
            </Button>
        </>
    )
}
