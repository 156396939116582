import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import personIcon from "src/images/mainPic/gatsby-astronaut.png"
import styled from "styled-components"

export { desideComment }

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        padding: "30px 10px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        marginBottom: "30px",
        alignItems: "center",
    },
    commenter: {
        position: "relative",
        bottom: "-20px",
        whiteSpace: "nowrap",
        fontSize: "13px",
        textAlign: "center",
    },
    text: {
        position: "relative",
        margin: "5px",
        top: "15px",
        marginBottom: "45px!important",
    },
    stamp: {
        position: "relative",
        fontSize: "30px",
        margin: "5px",
        top: "27px",
        marginBottom: "45px!important",
    },
    commentDate: {
        position: "absolute",
        fontSize: "10px",
        right: "2%",
        whiteSpace: "nowrap",
    },
    stampDate: {
        position: "absolute",
        fontSize: "4px",
    },
}))

function desideComment(hit, rate) {
    let text
    if (rate >= 0 && rate < 10) {
        text = "調子が悪いみたいじゃな。こんな日は温泉に入って寝るのが一番じゃ。"
        if (rate === 0) {
            text = "中たった本数が０本でも引いたことに意味があるんじゃよ。"
        }
        if (rate === 5) {
            text =
                "射型によくない癖があるかもしれないぞ。指導でよく言われることを試してみてはどうじゃ？"
        }
    } else if (rate >= 10 && rate < 20) {
        text = "キロ上げしてみるのはどうじゃ？少し重いと感じる程度がベストじゃ。"
        if (rate === 10) {
            text =
                "友達に射型を見てもらったり動画を撮ってもらうと上達が早いぞ。ワシの時代はカメラがなかったけど、今は便利になったものじゃのう。"
        } else if (rate === 15) {
            text =
                "前に飛ぶなら押手が効いてないぞ。離れで腕が下に落ちたり、手首がおれたり、角見が中ってなかったりしてないか？押し手は奥が深い分極めると無双できるんじゃ。"
        }
    } else if (rate >= 20 && rate < 30) {
        text = "休むことも必要じゃぞ。ワシが現役の時は、一週間に一日は休むことを心がけてたぞ。"
        if (rate === 20) {
            text =
                "引く時に意識してることはなんじゃ？忘れないためにも意識することは三つぐらいがええかのう。ワシは、年のせいで立ちに入ると全て忘れてしまうんじゃ。若いってええのう。"
        } else if (rate === 25) {
            text =
                "弓道は調子に波があるスポーツじゃ。だけど、練習次第で調子がいい日を増やすことはできるぞ。昔はすることがなかったから弓道三昧やったのう。"
        }
    } else if (rate >= 30 && rate < 40) {
        text =
            "離れは自然にでるとええぞ。会で親指を自分の身体の方にそらす力を加え続けると自然な離れができるはずじゃよ。"
        if (rate === 30) {
            text = "鏡を見て練習してる？自分で自分の射型を分析するのが上達の近道じゃ。"
        } else if (rate === 35) {
            text =
                "押手が上手い人、離れが上手い人、引き方が上手い人をyoutubeで見つけて真似をすると上達が早いって孫が言うとったのう。世の中便利になったのう。"
        }
    } else if (rate >= 40 && rate < 50) {
        text = "毎日コツコツ練習あるのみじゃ。一日練習休むと三日分の遅れが出るって聞くのう。"
        if (rate === 40) {
            text =
                "この辺の的中率をさまよう子が多いのう。均等に引き分けができてるか確認してみてはどうじゃ？"
        } else if (rate === 45) {
            text = "今日の的中率を低いと思うか、高いと思うかで今後の的中率が変わってくるんじゃよ。"
        }
    } else if (rate >= 50 && rate < 60) {
        text =
            "弓道歴が短い人は、基礎練無くして高的中をだすのは難しいぞ。基礎練習は欠かさずやるんじゃよ。"
        if (rate === 50) {
            text = "半矢は少しの工夫でもっと伸びるぞ。どう工夫するかは自分次第じゃ。"
        } else if (rate === 55) {
            text =
                "あと何個か意識することを加えれば、もっと中るようになる的中率じゃな。悪くないからこの調子で頑張って欲しいのう。"
        }
    } else if (rate >= 60 && rate < 70) {
        text =
            "自主練をもっとすればもっともっと中るぞ。もうしてると思うけど、ちょっと増やしてみてはどうじゃ？"
        if (rate === 60) {
            text =
                "射型は固まってそうじゃな。よくない癖は一つ一つ改善していくんじゃ。ワシは酒癖を直そうかのう。"
        } else if (rate === 65) {
            text =
                "的中ノートに意識することをまとめてみてはどうじゃ？書けば忘れていることが見つかるはずじゃ。ワシはあんまりしてなかったけどのう。"
        }
    } else if (rate >= 70 && rate < 80) {
        text = "試合で戦力になる的中じゃな。この調子で頑張るんじゃぞ"
        if (rate === 70) {
            text = "自惚れたらあかんぞ。中たらかった理由を考えることで成長できるもんじゃ。"
        } else if (rate === 75) {
            text =
                "平均3中で満足したらあかんぞ。さらなる的中を目指し練習に励むのじゃ。ワシは君に期待しておる。"
        }
    } else if (rate >= 80 && rate < 90) {
        text =
            "この的中を平均して出せるなら大したものじゃ。ワシが見込んだだけはある。キレのある離れと的に向かって押し続ける意識が大事じゃよ。"
        if (rate === 80) {
            text =
                "8割中るということは大分射型が完成されとるのう。あまり意識することは変えず、同じように引く練習をするとさらに伸びるぞ。"
        } else if (rate === 85) {
            text =
                "調子よさそうじゃな。ここまで中たればワシからは何も指導することはないかのう。弓は骨で引くと言われてるがワシはそうは思わんことだけは言うておくぞ"
        }
    } else if (rate >= 90 && rate < 100) {
        text = "全国で輝けるレベルじゃな。覚えていたら国体にワシから推薦しておこう。"
        if (rate === 90) {
            text =
                "気持ちいいぃぃくらい中たっておるようじゃな。あまり自分だけ気持ちよくなってはいかんぞ。友達に指導してみんなで強くなるんじゃ。"
        } else if (rate === 95) {
            text =
                "気を抜いてしまったようじゃな。 20射皆は難しいけど、これだけ調子がよかったらその一本中てれたはずじゃぞ。次は、気を抜かないようにするんじゃよ"
        }
    } else if (rate === 100) {
        text = "常人の域を超えたようじゃな。ワシからは何も言うことはない。おめでとう。"
    }
    return text
}

export default function CommentBox(props) {
    const classes = useStyles()
    const { commentText } = props
    return (
        <>
            {commentText.length > 0 && (
                <div className={classes.root}>
                    <div>
                        <ImgSize src={personIcon} />
                        <div className={classes.commenter}>AI</div>
                    </div>
                    <div className={classes.text}>
                        <Balloon>{commentText}</Balloon>
                    </div>
                </div>
            )}
        </>
    )
}

const Balloon = styled.div`
    position: relative;
    padding: 12px;
    border-radius: 10px;
    background: #cfe9e8;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    max-width: 100%;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -10px;
        border: 9px solid transparent;
        border-right: 20px solid #cfe9e8;
    }
`

const ImgSize = styled.img`
    width: 80px;
    max-width: none;
    @media (max-width: 480px) {
        width: 70px;
        height: 70px;
    }
`
