import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import TwitterIcon from "@material-ui/icons/Twitter"
import React from "react"

const useStyles = makeStyles((theme) => ({
    button: {
        color: "#fff",
        margin: theme.spacing(1),
        background: "rgba(29,161,242,1.00)",
        "&:hover": {
            background: "rgba(29,161,242,1.00)",
        },
        fontWeight: "bold",

        textTransform: "lowercase",
    },
}))

export default function TweetButton(props) {
    const classes = useStyles()
    const { records, commentText, distinction } = props
    let text, link, hashtags, title, lastRecords
    if (records.length > 0) {
        lastRecords = [...records].reverse()[0]
        switch (distinction) {
            case 0:
                title = "通常練習"
                break
            case 1:
                title = "強化練習"
                break
            case 2:
                title = "自主練習"
                break
        }
        // const [fortune, setFortune] = React.useState(0);
        text = encodeURIComponent(
            `${title} ${lastRecords.date}の的中率\n→${lastRecords.hit_arrow}/${
                lastRecords.all_arrow
            } ${Math.round(lastRecords.hit_rate)}%\n\nAIによる考察\n→${commentText}`
        )
        link = encodeURIComponent(`\n\nhttps://atoko-hitrate.web.app/`)
        hashtags = encodeURIComponent(`HitRate`)
    }

    const goTwitter = () => {
        const url = `https://twitter.com/intent/tweet?text=${text}&url=${link}&hashtags=${hashtags}`
        const option = "status=1,width=818,height=400,top=100,left=100"
        window.open(url, "twitter", option)
    }

    return (
        <>
            <Button
                disabled={records.length == 0}
                variant="contained"
                size="small"
                onClick={goTwitter}
                className={classes.button}
                startIcon={<TwitterIcon />}
            >
                tweet
            </Button>
        </>
    )
}
